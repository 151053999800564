<template lang="pug">
  div.wrap-common-header
    div.header-content.f.fh
      div(v-if="content.leftAction").left.f.fm
        v-icon(@click="content.leftAction.method" :color="content.leftAction.color").mr4 {{content.leftAction.icon}}
        span(:style="`color: ${content.leftAction.color};`") {{content.leftAction.label}}
      div.wrap-label
        span(v-if="content.label").label.line-clamp-1 {{content.label}}
      div(v-if="content.rightAction" @click="content.rightAction.method").right.f.fm
        v-icon(:color="content.rightAction.color").mr4 {{content.rightAction.icon}}
        span(:style="`color: ${content.rightAction.color};`") {{content.rightAction.label}}
      div(v-if="content.rightActions").right.f.fm
        div(v-for="item in content.rightActions" @click="item.method").f.fm
          v-icon(:color="item.color").ml2 {{item.icon}}
          span(:style="`color: ${item.color};`") {{item.label}}

</template>

<style lang="scss" scoped>
.wrap-common-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background: #fff;
  border-bottom: solid rgba(0, 0, 0, 0.4) 0.5px;
  z-index: 102;
  .header-content {
    position: relative;
    width: 93%;
    height: 100%;
    max-width: 820px;
    margin: 0 auto;
    .left {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      color: #2a2a2a;
      .logo {
        font-size: 16px;
      }
      img {
        height: 34px;
      }
    }
    .wrap-label {
      max-width: 220px;
      .label {
        font-size: 14px;
        color: #2a2a2a;
        font-weight: bold;
      }
    }
    .right {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      color: #2a2a2a;
    }
  }
}
</style>

<script>

export default {
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>
