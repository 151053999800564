<template lang="pug">
  div.wrap-module-inbox
    div(v-if="groups && groups[0]")
      ItemInboxCard(v-for="item in groups" :content="item")
    div(v-else).no-chat.f.fh
      span メッセージはありません。
</template>

<style lang="scss" scoped>
.wrap-module-inbox {
  width: 100%;
  max-width: 820px;
  min-height: calc(100vh - 48px);
  margin: 0 auto;
  padding-top: 48px;
  .no-chat {
    height: calc(100vh - 48px);
  }
}
</style>

<script>
import db from '@/components/utils/firebase'
import ItemInboxCard from '@/components/item/ItemInboxCard'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    ItemInboxCard
  },
  data () {
    return {
      groups: null
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  created () {
    db.collection('GROUPS')
      .where('authedClientUid', '==', this.uid)
      .orderBy('updated', 'desc')
      .onSnapshot(this.getGroups)
  },
  methods: {
    getGroups (q) {
      this.groups = q.docs.map(d => {
        var data = d.data()
        data.id = d.id
        return data
      })
    }
  }
}
</script>
