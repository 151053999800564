<template lang="pug">
  div.wrap-footer.f.fm
    div.footer-content.f.fm.flex-around
      div(v-for="item in footerContent"
          @click="$router.push(`/${item.to}`)")
        v-icon(:color="item.color") {{item.icon}}
</template>

<style lang="scss">
.wrap-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 48px;
  background: #fff;
  z-index: 101;
  border-top: solid rgba(0, 0, 0, 0.4) 0.5px;
  .footer-content {
    width: 93%;
    max-width: 420px;
    margin: 0 auto;
  }
  // .v-bottom-navigation {
  //   display: flex;
  //   align-items: center;
  //   .v-btn {
  //     padding: 0 12px !important;
  //   }
  // }
}
</style>

<script>
export default {
  data () {
    return {
      footerContent: [
        {
          to: 'client_inbox',
          icon: 'mdi-message-outline',
          color: '#999'
        },
        {
          to: 'client_schedule',
          icon: 'mdi-calendar-outline',
          color: '#999'
        },
        {
          to: 'client_record',
          icon: 'mdi-file-outline',
          color: '#999'
        },
        // {
        //   to: 'client_product',
        //   icon: 'mdi-cart',
        //   color: '#999'
        // },
        {
          to: 'client_account',
          icon: 'mdi-account-outline',
          color: '#999'
        }
      ]
    }
  },
  watch: {
    '$route': function (to, from) {
      if (to.path !== from.path) {
        this.footerContent = this.footerContent.map(e => {
          e.color = (this.$route.name === e.to) ? '#1867c0' : '#999'
          return e
        }, this)
      }
    }
  },
  created () {
    this.footerContent = this.footerContent.map(e => {
      e.color = (this.$route.name === e.to) ? '#1867c0' : '#999'
      return e
    }, this)
  }
}
</script>
