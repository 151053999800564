<template lang="pug">
  Auth(@loginFailed="onFailedAuthentication")
    ClientHeader(:content="headerContent")
    ModalPage(
      v-if="uid"
      :showModal="showModalPage"
      :modalPageContentId="modalPageContentId"
      @closeModalPage="closeModalPage")
    div.wrap-client-inbox
      ModuleClientInbox(v-if="uid")
    ClientFooter
</template>

<style lang="scss" scoped>
.wrap-client-inbox {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/auth'
import ClientHeader from '@/components/shared/ClientHeader'
import ClientFooter from '@/components/shared/ClientFooter'
import ModalPage from '@/components/shared/ModalPage.vue'
import ModuleClientInbox from '@/components/module/ModuleClientInbox'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    ClientHeader,
    ClientFooter,
    ModalPage,
    ModuleClientInbox
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  watch: {
    '$route': function (to, from) {
      if (to.path !== from.path) {
        if (this.$route.params.groupId) {
          this.showModalPage = true
          this.headerContent = {
            leftAction: {
              icon: 'arrow_back_ios',
              method: () => {
                this.$router.push('/client_inbox')
              }
            }
          }
        } else {
          this.showModalPage = false
          this.headerContent = {
            label: 'メッセージ'
          }
        }
      }
    }
  },
  data () {
    return {
      headerContent: {
        label: 'メッセージ'
      },
      showModalPage: false,
      modalPageContentId: 'chat'
    }
  },
  created () {
    if (this.$route.params.groupId) {
      this.showModalPage = true
      this.headerContent = {
        leftAction: {
          icon: 'arrow_back_ios',
          method: () => {
            this.$router.push('/client_inbox')
          }
        }
      }
    } else {
      this.showModalPage = false
      this.headerContent = {
        label: 'メッセージ'
      }
    }
  },
  methods: {
    ...mapActionsAuth(['signOut']),
    onFailedAuthentication () {
      this.$router.push('/client-sign-in')
    },
    closeModalPage () {
      this.showModalPage = false
    }
  }
}
</script>
